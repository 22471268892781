@import 'flexboxgrid/css/flexboxgrid.css';
@import 'swiper/css/bundle';
@import 'scss/_mixin.scss';
@import 'scss/_animation.scss';

:root {
  --color-black: #000000;
  --color-grey-darker: #d1d1d1;
  --color-grey-darkest: #8E8E8E;
  --color-grey: #8D8D8D;
  --color-lite-grey: #b8b7b8;
  --color-dark-grey: #737373;
  --color-dark-blue: #030C58;
  --color-lite-blue: #E7F5F8;
  --color-white: #fff;

  --font-size-default: 16px;
  --font-default: 1.1rem;

  --font-size-7: 3.3125rem;
  --font-size-6: 2.5rem;
  --font-size-5: 2rem;
  --font-size-4: 1.5rem;
  --font-size-3: 1.375rem;
  --font-size-2: 1.25rem;
  --font-size-1: 1.125rem;

  --clamp-size: 2vw;

  --font-primary: 'Libre Franklin', sans-serif;
  --font-secondary: 'Zilla Slab', serif;

  --global-padding: 1rem;
  --heading-margin: .25em 0 .75em 0;

  --layout-max-width: min(1500px, 100%);
  --slider-mobile-pad: 60vw;
}
* {
  font-display: swap;
}
table {
  width:100%;
}
html {
  padding:0;
  background-color: var(--color-dark-blue);
  font-size: var(--font-size-default);
  font-family: var(--font-primary);
}
strong {
  font-weight: 500;
}
body {
  padding:0;
  margin:0;
  background-color: var(--color-white);
}
.container {
  padding: var(--global-padding);
  box-sizing: border-box;
  width: var(--layout-max-width);
  margin:0px auto;
}
/* Typography */

h1, h2, h3, h4, h5, h6 {
  margin:var(--heading-margin);
  line-height: .9em;
  margin-bottom:.25em;
  &+p {
    padding-top:0;
    margin-top:0;
  }
  &.no-pad {
    padding:0;
    margin:0;

  }
}
h1, .font-size-7 {
  font-size: var(--font-size-7);
  font-family: var(--font-secondary);
  font-weight: 300;
  color: var(--color-dark-blue);
  line-height: 1em;
  font-display: swap;
  font-size: clamp(4em, 4vw, 6em);
}
h2, .font-size-6 {
  font-size: var(--font-size-6);
  font-family: var(--font-secondary);
  font-weight: 300;
  color: var(--color-dark-blue);
  line-height: 1em;
  font-display: swap;
}
h3, .font-size-5 {
  font-size: var(--font-size-5);
  font-family: var(--font-secondary);
  font-weight: 300;
  color: var(--color-black);
  line-height: 1em;
  font-display: swap;
}
h4, .font-size-4 {
  font-size: var(--font-size-4);
  font-family: var(--font-primary);
  font-weight: 300;
  color: var(--color-black);
  line-height: 1em;
  font-display: swap;
}
h5, .font-size-3 {
  font-size: var(--font-size-3);
  font-family: var(--font-secondary);
  font-weight: 700;
  color: var(--color-dark-blue);
  line-height: 1em;
  font-display: swap;
}
h6, .font-size-2 {
  color: var(--color-dark-blue);
  font-size: var(--font-size-2);
  font-family: var(--font-primary);
  font-weight: 700;
  line-height: 1em;
}

p, li, .font-size-1 {
  font-family: var(--font-primary);
  font-size: var(--font-size-1);
  font-weight: 300;
  line-height: 130%;
  font-display: swap;
}

/* End Typography */






form {
  button {
    background-color: var(--color-lite-blue);
    color: var(--color-dark-blue);
    border:3px solid var(--color-lite-blue);
    font-family: var(--font-secondary);
    font-size: var(--font-size-4);
    padding: .5em;
    cursor:pointer;
    outline:none;
    &:hover, &:focus {
      border:3px solid var(--color-dark-blue);
    }
    &:active {
      background-color: var(--color-dark-blue);
      color: var(--color-lite-blue);
    }
    font-display: swap;
  }
  button[disabled] {
    opacity: .2;
    cursor: not-allowed;
    font-display: swap;
  }

  label {
    color: var(--color-dark-blue);
    font-size: var(--font-size-3);
    font-family: var(--font-secondary);
    display: block;
    margin-bottom: .5em;
    .text-danger {
      color: red;
      margin:0;
      font-size: var(--font-size-1);
    }
    font-display: swap;
  }
  input, textarea, select {
    box-sizing: border-box;
    display: block;
    border: 2px solid var(--color-grey);
    background-color: var(--color-white);
    color: var(--color-dark-blue);
    font-size: var(--font-size-2);
    padding:.25em;
    width:100%;
    border-radius: 0;
    font-weight:600;
    &:focus {
      outline:none;
      border-radius: 0;
      border: 2px solid var(--color-dark-blue);
    }
    &.ng-invalid.ng-touched {
      border-color:red;
    }
    font-display: swap;
  }

  textarea {
    min-height: 6em;
  }

  label {
    >input[type=radio] {
      ~ span {
        border-radius: 50%;
      }
    }
    > input[type=checkbox], > input[type=radio] {
      cursor: pointer;
      position: absolute;
      left:-50000px;
      ~ span {
        display: inline-block;
        width:1rem;
        height:1rem;
        border: 2px solid var(--color-grey);
        color: var(--color-dark-blue);
      }
      &:hover, &:focus{
        ~ span {
          background-color: var(--color-lite-blue);
          position: relative;
          &:after {
            content: "";
            bottom: -0.25em;
            border-bottom: 2px solid var(--color-grey);
            position: absolute;
            width:100%;
          }
        }
      }
      &:checked ~ span {
        background-color: var(--color-dark-blue);
      }
    }
  }
}
/* End Forms */


/* Site */

.primary-header {
  position: fixed;
  width:100%;
  background-color: var(--color-white);
  z-index: 10;
  top:0;
  left:0;
  display: flex;
  justify-content: space-between;
}
.primary-header + main {
  margin-top: 75px;
}
.primary-nav {
  display: flex;
  position: fixed;
  overflow: hidden;
  top:0;
  left:100%;
  z-index: 2;
  align-items: center;
  width:100vw;
  height:100vh;
  background-color: var(--color-white);
  ul {
    padding:1em;
    list-style: none;
    text-align: center;
    width:100%;
    max-width: 600px;
    margin: 0px auto;
    li {
      border-bottom:3px solid var(--color-grey);
    }
  }
  a {
    display: inline-block;
    color: var(--color-dark-blue);
    font-size: var(--font-size-5);
    text-decoration: none;
    font-family: var(--font-secondary);
    text-transform: uppercase;
    padding: .5em;
    font-weight: 500;
    &:hover, &:focus {
      background-color: var(--color-lite-blue);
      text-decoration: none;
    }
    &:active, &.active {
      background-color: var(--color-dark-blue);
      color: var(--color-white);
    }
  }
}
.link {
  color: var(--color-dark-blue);
  font-family: var(--font-secondary);
  font-size: var(--font-size-2);
  &:after {
    content: ' >>';
  }
}


.hr-box {
  --border: 2px solid var(--color-lite-grey);
  text-align: center;
  border-top:var(--border);
  border-bottom:var(--border);
  padding:1rem;
}
.primary-footer {
  background-color: var(--color-dark-blue);
  color: var(--color-white);
  font-family: var(--font-secondary);
  font-size: var(--font-size-1);
  a {
    color: inherit;
    font-family: inherit;
  }
  > div.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
      flex-grow: 1;
    }
    p {
      font-size: .8em;
      text-align: center;
      font-family: inherit;
      font-weight: 600;
    }
  }
}

button.open-button {
  outline:none;
  border:none;
  background:transparent;
  cursor:pointer;
  position: fixed;
  top:var(--global-padding);
  right:var(--global-padding);
  z-index: 3;
  height: 30px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin:0;
  padding:0;
  box-sizing: border-box;
  border-radius: 50%;
  > span {
    height: 5px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--color-dark-blue);
    display: block;
    animation-duration: 400ms;
    transform-origin: 50% 50%;
    transition: transform 0.2s ease-in-out;
    position: absolute;
    &:nth-child(1) {
      top:10%;
      margin-top:-2.5px;
      animation-name: topDownR;
    }
    &:nth-child(2) {
      top:50%;
      margin-top:-2.5px;
      animation-name: scaleUp;
    }
    &:nth-child(3) {
      margin-bottom:-2.5px;
      bottom:10%;
      animation-name: bottomUpR;
    }
  }
  &.init + #primary-nav {
    animation-duration: 200ms;
    animation-fill-mode: both;
    animation-name: slideOut;
  }
  &[aria-expanded=true] {
    > span {
      animation-fill-mode: forwards;
      &:nth-child(1) {
        animation-name: topDown;
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        animation-name: bottomUp;
      }
    }
    & + #primary-nav {
      position: fixed;
      width:100%;
      height:100%;
      left:100%;
      animation-name: slideIn;
    }
  }
  &:before {
    --before-size: 3.25em;
    content:'';
    background-color: transparent;
    position: absolute;
    left:50%;
    top:50%;
    border-radius: 50%;
    width: var(--before-size);
    height: var(--before-size);
    margin-left:calc(var(--before-size) / 2 * -1);
    margin-top:calc(var(--before-size) / 2 * -1);
  }
  &:focus, &:hover  {
    &:before {
      background-color: var(--color-grey);
      opacity: .2;
      transform:scale(1.6);
      transition: all 200ms ease;
    }
  }
}

mat-card {
  margin-bottom:1em;
}

.swiper .swiper-slide img {
  width:100%;
}

.arrow {

  cursor:pointer;
  height:74px;
  width:35px;
  display: inline-block;
  outline:0;
  border:0;
  background:transparent;
  position: relative;
  transition: transform 200ms ease;
  &:active, &:hover, &:focus   {
    transform:scale(1.2);
  }
  &:before, &:after {
    content:'';
    display: inline-block;
    border-radius: 1em;
    height:6px;
    width: 50px;
    background-color: var(--color-dark-blue);
    position: absolute;
    left:0;
  }
  &:before {
    top:50%;
    transform-origin: top left;
    transform:rotate(45deg);
    margin-top:-3.7px;
  }
  &:after {
    bottom:50%;
    margin-bottom:-3.7px;
    transform-origin: bottom left;
    transform:rotate(-45deg);
  }
  &.right {
    transform: rotate(180deg);
    &:active, &:hover, &:focus   {
      transform:scale(1.2) rotate(180deg);
    }
  }
}
.testimonials {
  .swiper-slide {
    overflow: hidden;
  }
  position: relative;
  .prev, .next {
    position: absolute;
    top:var(--slider-mobile-pad);;
    z-index: 3;
  }
  .prev {
    left: calc(50% - (35px * 1.5));
  }
  .next {
    right: calc(50% - (35px * 1.5));
  }
  background-color: var(--color-lite-blue);
  .testimonial {
    .testimonial-image {
      margin-bottom:50px;
      height:var(--slider-mobile-pad);
    }
  }
}
button.skip-to-content {
  border: 0;
  clip: rect(1px,1px,1px,1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute!important;
  width: 1px;
  word-wrap: normal!important;
  &:focus {
    background-color: #f1f1f1;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 2px 2px rgb(0 0 0 / 60%);
    box-shadow: 0 0 2px 2px rgb(0 0 0 / 60%);
    clip: auto!important;
    -webkit-clip-path: none;
    clip-path: none;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: .875rem;
    font-weight: 700;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
  }
}

.row.pad .box {
  padding:var(--global-padding);
}
@keyframes scaleDown {
  0% {
    transform:scale(1);
  }
  50% {
    transform:scale(0);
  }
  100% {
    transform:scale(0);
  }
}
@keyframes scaleUp {
  0% {
    transform:scale(0);
  }
  50% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}
@keyframes topDownR {
  0% {
    top: 50%;
    transform:rotate(45deg);
  }
  50% {
    top: 50%;
    transform:rotate(0);
  }
  100% {
    top: 10%;
  }
}
@keyframes bottomUpR {
  0% {
    bottom: 50%;
    transform:rotate(45deg);
  }
  50% {
    bottom: 50%;
    transform:rotate(0);
  }
  100% {
    bottom: 10%;
  }
}

@keyframes topDown {
  0% {
    top: 10%;
    margin:0;
  }
  50% {
    top: 50%;
    transform: rotate(0deg);
  }
  100% {
    top: 50%;
    transform: rotate(45deg);
  }
}
@keyframes bottomUp {
  0%   {
    bottom: 10%;
    margin:0;
  }
  50% {
    bottom: 50%;
    transform: rotate(0deg);
  }
  100% {
    bottom: 50%;
    transform: rotate(-45deg);
  }
}


/* End Site */

/* TMP */

.section-heading {
  color: var(--color-grey-darkest);
  border-bottom: 1px solid var(--color-grey-darker);
}


fieldset {
  border: none;
  padding:0;
  margin:0;
}

fieldset.ng-touched.ng-invalid {
  border:1px solid;
}

ul.rules {
  border:1px solid #f1f1f1;
}

.section-price {
  &:before {
    content: "$";
  }
  text-align: right;
  color: var(--color-black);
  font-size: var(--font-size-2);
  text-transform: uppercase;
  font-weight: 500;
  margin:0;
}

.mat-card-nested {
  padding:1em;
  background-color: #fafafa;
  border:1px solid #ededed;
  border-radius: 5px;
  mat-card {
    margin-bottom:1em;
  }
}

mat-form-field {
  &.full {
    display: block;
  }
}
/* END TMP */


.gallery {
  .quote {
    p {
      @extend .font-size-4;
      font-family: var(--font-secondary);
      &:before { content: open-quote; }
      &:after  { content: close-quote; }
    }
    span {
      @extend .font-size-3;
      text-transform: uppercase;
    }
  }
}


blockquote {
  position: relative;
  &:before, &:after {
    content:'';
    background-image:url('/assets/images/quote.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: calc(var(--font-size-1) * 10);
    height: calc(var(--font-size-1) * 10);
    display: inline-block;
    position: absolute;
    z-index: -1;
  }
  &:before {
    top: 0px;
    left: 0px;
    margin-left:calc(var(--font-size-1) * 2 * -1);
  }
  &:after {
    bottom: 0px;
    right: 0px;
    transform:rotate(180deg);
    margin-right:calc(var(--font-size-1) * 2 * -1);
  }
  p {
    font-family: var(--font-secondary);
    font-size: clamp(var(--font-size-3), 2vw, var(--font-size-4));
    &:before { content: open-quote; }
    &:after  { content: close-quote; }
  }

  margin:0;
  padding:0;
  span {
    @extend .font-size-2;
  }
}
@include for-tablet {
  :root {
    --font-size-7: 6rem;
    --font-size-6: 4rem;
    --font-size-5: 3.375rem;
    --font-size-4: 2.4375rem;
    --font-size-3: 2.125rem;
    --font-size-2: 1.875rem;
    --font-size-1: 1.5rem;
    --global-padding: 2rem;
    --clamp-size: 4vw;
  }

  .primary-header {
    position: static;
    .container {
      display: flex;
    }
    .open-button {
      display: none;
    }
    app-menu {
      margin-left:auto;
    }
  }
  .primary-header + main {
    margin:0;
  }
  button.open-button.init + #primary-nav , nav#primary-nav {
    position: static;
    width:auto;
    height:auto;

    ul {
      display: flex;
      max-width: none;
      padding:0;
      li {
        border-bottom: none;
      }
    }
    a {
      font-size:clamp(var(--font-size-1), 2.5vw, var(--font-size-2));
      padding: .25em .5em .25em .5em;
    }
  }
  .primary-footer {
    > div.container {
      flex-direction: row;
      p {
        text-align: right;
      }
    }
  }

  .col {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &.two-col > * {
      width:50%;
    }
    .align-bottom {
      align-self:flex-end;
    }
    .pad {
        padding: var(--global-padding);
    }
  }

  .testimonials {
    position: relative;
    .testimonial {
      .testimonial-image {
        margin:0;
        height:auto;
      }
    }
    .prev, .next {
      z-index: 2;
      position: absolute;
      top:50%;
      margin-top:-35px;
    }
    .prev {
      left: var(--global-padding);
    }
    .next {
      right: var(--global-padding);
    }
  }
}
@include for-desktop-lg {


  .primary-header {
    position: relative;
    background-color: transparent;

    .logo {
      padding:1em;
      padding-top: var(--global-padding);
      top:0;
      position: absolute;
      background-color: rgba(255,255,255,.9);
    }
  }

  .col {
    &.three-col > * {
      width:33%;
    }
    &.four-col > * {
      width:25%;
    }
  }
}
