@mixin for-tablet {
  @media (min-width: 48em) { @content; }
}

@mixin for-desktop {
  @media (min-width: 62em) { @content; }
}
@mixin for-desktop-lg {
  @media (min-width: 75em) { @content; }
}
@mixin for-desktop-xl {
  @media (min-width: 100em) { @content; }
}
