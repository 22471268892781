@keyframes slideIn {
  0%   {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}
@keyframes slideOut {
  0% {
    left:auto;
    right: 0%;
  }
  100% {
    left:auto;
    right: 100%;
  }
}
